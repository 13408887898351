<template>
	<div class="gamification">
		<div class="gamification__header d-flex">
			<div class="gamification__header-container p-0 col-12 col-md-8 mb-3">
				<TitlePage
					class="mb-2"
					:title="$t('gamification.ranking_caption_title')"
				>
					<IconSax name="cup" />
				</TitlePage>

				<div class="gamification__header-container__info">
					<TextPlay
						color="var(--text-color)"
						:text="$t('gamification.ranking_description')"
					/>
				</div>
			</div>

			<ButtonPlay
				icon="edit"
				:text="$t('gamification.to_edit_gamification')"
				link="/gamification/config"
			/>
		</div>

		<DividerPlay class="mt-4 mb-5" />

		<div class="gamification__ranking col-12 col-md-9 p-0">
			<RankingList
				:skeleton="isLoadingFetch"
				:rankingList="rankingList"
			/>
			<Observer
				v-if="rankingList.length > 19 && !isLastPage"
				@on-change="onChange"
				class="d-flex justify-content-center mt-4"
			>
				<b-spinner label="Spinning"></b-spinner>
			</Observer>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TitlePage from "./common/TitlePage.vue";
import RankingList from "@/components/RankingList.vue";
import Observer from "vue-intersection-observer";

export default {
	components: {
		TitlePage,
		RankingList,
		Observer,
	},
	data() {
		return {
			isLoadingFetch: false,
			currentPage: 1,
			rankingList: [],
			isLastPage: false,
		};
	},
	methods: {
		...mapActions({ fetchRankingForSeller: "gamification/fetchRankingForSeller" }),
		async onChange(entry) {
			if (entry.isIntersecting) {
				await this.fetchRankingForSeller(this.currentPage + 1);
				this.currentPage++;
			}
		},
	},
	computed: {
		...mapGetters({ lastPageRankingForSeller: "gamification/getLastPageRankingForSeller" }),
	},
	watch: {
		lastPageRankingForSeller(lastPage) {
			if (!lastPage || lastPage.length === 0) {
				this.currentPage--;
				this.isLastPage = true;
				return;
			}
			this.rankingList = [...this.rankingList, ...lastPage];
		},
	},
	async mounted() {
		this.isLoadingFetch = true;
		await this.fetchRankingForSeller(1);
		this.isLoadingFetch = false;
	},
};
</script>

<style lang="scss" scoped>
.gamification {
	padding: 64px 40px;

	&__header {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

@media (max-width: 768px) {
	.gamification {
		padding: 40px 16px;

		&__header {
			flex-direction: column;
			align-items: start;
		}
	}
}
</style>
