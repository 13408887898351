<template>
	<Layout>
		<GamificationRankingTemplate />
	</Layout>
</template>

<script>
import GamificationRankingTemplate from "@/components/GamificationRankingTemplate.vue";

export default {
	components: {
		GamificationRankingTemplate,
	},
};
</script>
